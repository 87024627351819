import React, { useEffect, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Nodata, NodataTitle, NodataDesc, NodataMedia, NodataGrid, NodataItem } from "../components/Nodata"
import styled from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Section, Container, SectionPageTitle, DropSearch, DropSearchBody, DropSearchItem } from "../components/Section"
import { SearchIcon, SearchCloseIcon } from "../components/Icons"
import { useSearch } from '../useSearch';
import { navigate } from 'gatsby';
import SearchProducts from '../components/SearchProducts';

const SearchForm = styled.div`
  background-color: #eef0fd;
  border:1px solid #babfdf;
  border-radius: 6px;
  width: 100%;
  max-width: 460px;
  margin:0 auto 30px auto;
  position: relative;
  z-index: 101;
  @media (min-width:1200px) {
    margin:0 auto 60px auto;
  }
  @media (min-width:1600px) {
    margin:0 auto 80px auto;
  }
  .input-icon{
    > .form-control{
        @media (min-width: 1200px){
          padding: 15px;
        }
        &::placeholder {
            color:#363636;
        }
        &:focus,
        &:active {
            &::placeholder {
            color:#363636;
            }
        }
    }
    .icon {
      width:45px;
      > svg{
        fill:#363636;
        width:20px;
      }    
      &:hover {
        > svg {
          fill: #e9242a;
        }
      }
    }
    &.right {
        > .form-control{
            padding-right: 45px;
            padding-left: 15px;
        }
    }
  }
`
const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 768px) {
    &.grid-list {
      flex-direction: column;
      .product-item {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .card {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        text-align: left;
        .card-body {
          flex: 0 0 43%;
          max-width: 43%;
        }
        .card-footer {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
          padding: 20px;
          .list-info {
            justify-content: flex-start;
          }
        }
      }
    }
    &.grid-view {
      flex-direction: row;
    }
  }
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 15px;
  }
`;

const SearchPage = ({ location }) => {
  const [search, setSearch] = useState(location.state?.searchKey || "");
  const [showDropdown, setShowDropdown] = useState(false);
  const [mobileResults, setMobileResults] = useState([]);
  const targetRef = useRef(null);
  const searchInputRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const { results } = useSearch(search);

  const handleClickOutside = (event) => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      if (event.target.id !== searchInputRef.current.id) {
        setShowDropdown(false);
      }
    }
  }

  const handlePlatform = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    setMobileResults(results);
    document.addEventListener('click', handleClickOutside);
    document.addEventListener("resize", handlePlatform);
    handlePlatform();
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener("resize", handlePlatform);
    };
  }, [])

  const applySearch = () => {
    setMobileResults(results);
  }
  
  const renderSearchResult = () => {
    const products = (isMobile ? mobileResults : results).filter(item => item.type !== "tag");

    if (!products || !products.length) {
      return (
        <Nodata>
          <NodataGrid>
            <NodataItem>
              <NodataMedia>
                <StaticImage src="../images/no-result.png" alt="No Results Found" />
              </NodataMedia>
            </NodataItem>
            <NodataItem>
              <NodataTitle className="h4 mb-15">No Results Found</NodataTitle>
              <NodataDesc mb="30px">
                <p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the post..</p>
              </NodataDesc>
            </NodataItem>
          </NodataGrid>
        </Nodata>
      )
    }

    return (
      <ProductGrid className="grid-list">
        <SearchProducts products={products} />
      </ProductGrid>
    )
  }

  return (
    <Layout hideSearch location={location}>
      <Seo title="Search Result" description="Search Result" />
      <Section
        ept="180px"
        epb="60px"
        xpt="140px"
        xpb="40px"
        pt="130px"
        pb="30px"
        bgColor="#fff" className="section-search" >
        <Container maxWidth="1170px" id="search-content">
          <SectionPageTitle textAlign="center">Search Results</SectionPageTitle>
          <SearchForm className='search-form'>
            <div className="input-icon right">
              <input
                ref={searchInputRef}
                className="form-control"
                type="text"
                placeholder="Search By Product..."
                id="search"
                name="search"
                value={search}
                onFocus={() => setShowDropdown(true)}
                autoComplete='off'
                onChange={e => {
                  setSearch(e.target.value)
                  setShowDropdown(true)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setShowDropdown(false);
                    applySearch();
                  }
                }}
              />
              <span className="icon" onClick={applySearch}><SearchIcon /></span>
              <span className="icon" style={{ display: "none" }}><SearchCloseIcon /></span>
            </div>
            {showDropdown && (
              <DropSearch ref={targetRef} className='drop-search'>
                <DropSearchBody>
                  {!!results && results.map((item, i) => (
                    <DropSearchItem
                      key={i}
                      onClick={() => {
                        if (item.type === "tag") {
                          navigate("/shop-metal-buildings", {
                            state: { tag: item.title },
                          })
                        } else {
                          navigate(item.url);
                        }
                      }}
                    >
                      {item.title}
                    </DropSearchItem>
                  ))}
                </DropSearchBody>
              </DropSearch>
            )}
          </SearchForm>

          {renderSearchResult()}
        </Container>
      </Section>
    </Layout>
  )
}

export default SearchPage